<template>
    <v-container fluid>
        <!-- PRODUCT PAGE TITLE -->
        <v-row align="center" class="mt-16" justify="center">
            <p class="centuryGothicBold screenTitle mt-4">{{ productTitle }}</p>
        </v-row>

        <!-- DESCRIPTION SECTION -->
        <v-row class="descriptionSection">
            <!-- IMAGES & TECH DATASHEET COLUMN -->
            <v-col align="center" xs="12" sm="12" md="5" lg="5" xl="5">
                <!-- PRODUCT IMAGE -->
                <img
                    :alt="productTitle"
                    :class="productFamily"
                    class="productImage"
                    :src="require('../../assets/products/' + productImg)"
                >

                <!-- DOWNLOAD TECHNICAL SPECS FILE BUTTON -->
                <v-btn
                    v-for="datasheet in productDatasheet"
                    :key="datasheet"
                    class="centuryGothicBold infoTableButton"
                    color="#797574"
                    dark
                    download
                    elevation="6"
                    :href="datasheet"
                    style="margin-top: 20px; padding: 20px 40px;"
                >Ver ficha técnica
                </v-btn>
            </v-col>

            <!-- DETAILS COLUMN -->
            <v-col xs="12" sm="12" md="7" lg="7" xl="7">
                <v-row align="start" class="pa-0" justify="start">
                    <v-col class="pa-0">
                        <!-- PRODUCT'S FAMILY TITLE -->
<!--                        <p class="centuryGothic familyTitle">{{ productFamily.toUpperCase() }}</p>-->

                        <!-- PRODUCT DESCRIPTION -->
                        <p class="centuryGothic mb-8">
                            <span class="centuryGothicBold bolderText">
                                {{ productTitle.toUpperCase() }} MEZCLA BRAVA
                            </span>
                            {{ productDescription }}
                        </p>

                        <!-- USAGES TEXT -->
                        <span class="centuryGothicBold bolderText">APLICACIONES</span>
                        <p class="centuryGothic mb-8">{{ productUses }}</p>


                        <!-- BENEFITS TEXT -->
                        <span class="centuryGothicBold bolderText">VENTAJAS</span>
                        <p class="centuryGothic mb-6">
                            <span v-for="(benefit, index) in productBenefits" :key="index">- {{benefit}} <br></span>
                        </p>

                        <!-- AVAILABLE PRESENTATIONS CONTAINER -->
                        <v-row>
                            <v-col cols="12" md="5" class="px-md-2 px-12" align-self="center">
                                <v-row justify="space-around">
                                    <v-col cols="6">
                                        <v-img
                                            src="../../assets/icons/Soluciones_Calidra.svg"
                                            alt="Soluciones Calidra"
                                        ></v-img>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-img
                                            src="../../assets/icons/Solo_agrega_agua.svg"
                                            alt="Soluciones Calidra"
                                        ></v-img>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="7" align-self="center">
                                <v-container id="presentationsContainer">
                                    <v-row align="center" class="mx-8 pt-5" justify="space-between">
                                        <!-- PRESENTATIONS LABEL -->
                                        <v-col class="pa-0" cols="5" md="4">
                                            <v-row justify="start">
                                                <p class="centuryGothic presentationText" style="text-align: center;">
                                                    Presentaciones<br>Disponible
                                                </p>
                                            </v-row>
                                        </v-col>

                                        <v-col class="pa-0" cols="7" md="8">
                                            <v-row align="start" justify="center">
                                                <!-- BAG ICON & LABEL -->
                                                <v-col align="center" class="pa-0" cols="6" v-if="productPresentations[0] === true">
                                                    <img
                                                        alt="Saco"
                                                        class="presentationIcon"
                                                        src="../../assets/icons/saco.svg"
                                                    >

                                                    <p class="centuryGothic presentationText" style="text-align: center;">
                                                        En Saco
                                                    </p>
                                                </v-col>

                                                <!-- SILO ICON & LABEL -->
                                                <v-col align="center" class="pa-0" cols="6" v-if="productPresentations[1] === true">
                                                    <img
                                                        alt="Silo"
                                                        class="presentationIcon"
                                                        src="../../assets/icons/silo.svg"
                                                    >


                                                    <p class="centuryGothic presentationText" style="text-align: center;">
                                                        A Granel en Silo
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- USAGES EXAMPLES SECTION -->
        <v-row align="start" class="usagesContainer mt-10" justify="start">
            <v-col class="pa-0">
                <!-- USAGES EXAMPLES TITLE -->
                <p class="centuryGothicBold productTitle">APLICACIONES</p>

                <!-- USAGES EXAMPLES IMAGES WITH LABEL -->
                <v-row align="center" justify="start">
                    <v-col sm="12" md="4" v-for="(item, index) in productUsesExamples" :key="index">
                        <!-- USECASE IMAGE -->
                        <v-img
                            class="useCaseImage"
                            :src="require('../../assets/usecases/' + item.img)"
                            transition="scale-transition"
                        ></v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- SIMILAR PRODUCTS SECTION -->
        <v-row align="start" class="mt-10 usagesContainer" justify="start">
            <v-col class="pa-0">
                <!-- SIMILAR PRODUCTS TITLE -->
                <p class="centuryGothicBold productTitle text-uppercase mt-6">Otros productos Mezcla Brava</p>

                <!-- SIMILAR PRODUCTS CARDS -->
                <v-row align="center" justify="space-around">
                    <v-col cols="12" sm="6" md="4" v-for="(product, index) in similarList" :key="index">
                        <Product_Card :product="product"></Product_Card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Product_Card from "../../components/cards/Product_Card";
import database from '../../constants/products_database.json';

export default {
    name: "Product",
    components: {
        Product_Card,
    },
    created() {
        const fullPath = this.$router.currentRoute.fullPath.substring(9);
        const parsed_data = fullPath.split('=');
        const product = parsed_data[1].split('&');
        this.productId = parseInt(product[0]);
        // console.log("PRODUCT ID --> " + this.productId);
        this.productFamily = product[1];
        this.productTitle = this.parsedProductName(parsed_data[0]).toUpperCase();
        const productData = database['families'][this.productFamily.toLowerCase()]['products'][this.productId];
        this.productImg = productData['img'];
        this.productPresentations = [productData['bag'], productData['silo']];
        this.productDescription = productData['description'];
        this.productUses = productData['uses'];
        this.productBenefits = productData['benefits'];
        this.similarList = [
          database['families'][this.productFamily.toLowerCase()]['products'][productData['similar'][0]],
          database['families'][this.productFamily.toLowerCase()]['products'][productData['similar'][1]],
          database['families'][this.productFamily.toLowerCase()]['products'][productData['similar'][2]]
        ];
        this.productUsesExamples = productData['examples'];
        this.productDatasheet = productData['datasheet'];
    },
    data() {
        return {
            productId: null,
            productTitle: null,
            productFamily: null,
            productImg: null,
            productDescription: null ,
            productUses: null,
            productBenefits: null,
            productPresentations: null,
            productUsesExamples: null,
            similarList: null,
            productDatasheet: null
        }
    },
    methods: {
        parsedProductName(name) {
            return name.replace(/_/g, ' ').toLowerCase();
        }
    },
    metaInfo() {
        return {
            title: 'Mezcla Brava | ' + this.productTitle.toUpperCase() + ' | FAMILIA ' + this.productFamily.toUpperCase(),
            meta: [
                { vmid: 'description', name: 'description', content: this.productDescription },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'twitter:image', name: 'twitter:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:url', name: 'og:url', content: 'https://www.mezclabrava.com/' },
                { vmid: 'twitter:url', name: 'twitter:url', content: 'https://www.mezclabrava.com/' },
            ]
        }
    },
}
</script>

<style scoped>
.productTitle {
    color: #3D3533;
    font-size: 2em;
}

.productImage {
    width: 80%;
}

.productImage:hover {
    /* offset-x | offset-y | blur-radius | color */
    box-shadow: 0 8px 20px lightgray;
}

.familyTitle {
    color: #3D3533;
    font-size: 1.75em;
}

.bolderText {
    color: #3D3533;
}

#presentationsContainer {
    border: #707070 1px solid;
    border-radius: 45px;
}

.presentationIcon {
    width: 50px;
}

.presentationText {
    font-size: 0.9em;
}

.useCaseImage {
    width: 100%;
    height: 35vh !important;
}

.rellena {
    border: #231F20 2px solid;
}

.levanta {
    border: #FF883C 2px solid;
}

.recubre {
    border: #0699CE 2px solid;
}

.coloca {
    border: #EE403D 2px solid;
}

.descriptionSection {
    padding: 0 50px;
}

.usagesContainer {
    padding: 0 50px !important;
}

.similarProductsRow {
    padding: 0 ;
}

@media (max-width: 960px) {
    .descriptionSection {
        padding: 0 20px;
    }

    .familyTitle {
        text-align: center;
        margin-top: 20px !important;
    }

    #presentationsContainer {
        font-size: 0.9em;
    }

    .usagesContainer{
        padding: 0 25px !important;
    }

    .productTitle {
        text-align: center;
    }
}
</style>